@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.container {
  @include flexbox;

  > :first-child {
    flex: 19;
  }

  > :last-child {
    flex: 1;
    @include flexbox;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

}

.tableHeader {
  > :first-child {
    width: 40%;
  }
}