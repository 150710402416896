@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.contract-list-item {
  &__header {
    display: flex;
    align-items: center;
    gap: $f-girot-space-xs;
    padding-top: $f-girot-space-xxs;

    & .icon-pill {
      margin-right: 0;
    }
  }

  &__header-title {
    flex: 1;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: $f-girot-space-xs;
  }

  &__titles {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: $f-girot-space-s;
    padding: 0 $f-girot-space-xs $f-girot-space-xs $f-girot-space-xs;

    & span {
      color: $f-girot-neutral-neutral-700;
    }
  }

  &__row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: $f-girot-space-s;
    background-color: $f-girot-neutral-neutral-20;
    border-radius: $f-girot-border-radius-l;
    padding: $f-girot-space-xxs $f-girot-space-xs;

    & span {
      color: $f-girot-neutral-neutral-700;
    }
  }
}
