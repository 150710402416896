@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin payment-settings-drawer {
}

.payment-settings-drawer {
  @include flexbox;
  flex-direction: column;
  height: auto;
  margin-top: $f-girot-space-m;
  gap: $f-girot-space-s;

  &__button {
    flex: 1;
  }
}
