@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.economy-overview-tab {
  margin-top: $f-girot-space-m;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: $f-girot-space-m;
}
