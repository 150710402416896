@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.update-employee-table {
  &__title {
    margin-top: 24px;
    @include flexbox;

    &__icon {
      margin-right: 8px;
      path {
        stroke: $f-girot-red-red-600;
      }
    }
  }

  &__table {
    margin-top: 8px;
    overflow: visible;

    p {
      color: $f-girot-neutral-neutral-600;
    }
    .table-cell {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  &__row {
    &__key {
      color: $f-girot-red-red-600;
    }
  }
}
