@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.search-filters-modal {
  display: flex;
  flex-direction: column;
  gap: $f-girot-space-s;

  &__icon {
    margin-right: $f-girot-space-xs;

    path {
      stroke: $f-girot-green-green-400;
    }
  }
}

.filter-dropdown {
  max-width: 13.5rem;
}
