@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin policy-version-header {
}

.policy-version-drawer-navigation {
  height: auto;
  display: flex;
  &__version-info {
    width: 100%;
    display: flex;
    &--pill {
      margin: 4px;
    }
    &--icon {
      margin: 4px;
    }
    &--typography {
      margin: 4px;
    }
  }
}
