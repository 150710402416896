@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin supplier-info-table {
}

.supplier-info-table {
  &__table {
    margin-top: $f-girot-space-m;

    .table-cell {
      padding-right: 8px;
    }
  }
  &__title {
    color: $f-girot-neutral-neutral-600;
    margin-bottom: $f-girot-space-s;
  }
}
