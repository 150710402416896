@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.unhandled-error {

  &__error-section {
    code {
      font-size: small;
      color: $f-girot-red-red-700;
      padding: 1%;
    }   
  }

  &__icon {
    path {
      stroke: $f-girot-orange-orange-500;
    }
    margin-right: $f-girot-space-xs;
  }

  &__error-section {
    max-height: 24rem;
    overflow-y: scroll;
  }

  &--overflow {
    margin-right: 8px;
  }
}
