@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.publish-draft-employee-modal {
  height: auto;
  min-height: 115px;
  display: flex;
  flex-direction: column;
  gap: $f-girot-space-s;

  &__period {
    display: flex;
    flex-direction: column;
    gap: $f-girot-space-xs;
  }

  &__errors {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: $f-girot-space-xs;
  }
}
