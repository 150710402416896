@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin policy-benefit-overview {
}

.policy-benefit-overview {
  &__tab {
    margin-top: $f-girot-space-m;
  }
  &__table {
    margin-bottom: $f-girot-space-m;
  }
}
