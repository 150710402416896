@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.document-template-tab {
  @include flexbox;
  margin-top: $f-girot-space-s;

  &__table {
    margin-top: $f-girot-space-s;
  }
}
