@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin update-supplier-info {
}

.update-supplier-info {
  &__subtitle {
    @include flexbox;

    &__number {
      color: $f-girot-neutral-neutral-700;
      margin-left: $f-girot-space-xs;
    }
  }
}
