@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

div.reporting-events-search-result {
  --size-s: calc(100% / 13.5);
  --size-m: calc(100% / 9);
  --size-l: calc(100% / 4.5);

  padding: 0;
  margin-bottom: 16px;

  & .list-item {
    & .table__container {
      padding: 0;
    }

    & td {
      font-size: 12px;
    }

    & th:nth-child(1),
    & td:nth-child(1) {
      width: var(--size-l);
    }

    & th:nth-child(2),
    & td:nth-child(2) {
      width: var(--size-s);
    }

    & th:nth-child(3),
    & td:nth-child(3) {
      width: var(--size-m);
    }

    & th:nth-child(4),
    & td:nth-child(4) {
      width: var(--size-s);
    }

    & th:nth-child(5),
    & td:nth-child(5) {
      width: var(--size-s);
    }

    & th:nth-child(6),
    & td:nth-child(6) {
      width: var(--size-m);
    }

    & th:nth-child(7),
    & td:nth-child(7) {
      width: var(--size-m);
    }

    & th:nth-child(8),
    & td:nth-child(8) {
      width: var(--size-m);
    }

    & th:nth-child(9),
    & td:nth-child(9) {
      width: var(--size-l);
    }
  }
}
