@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.document-template-drawer {
  &__generate-tab {
    padding-top: $f-girot-space-xs;
  }
  &__container_table {
    font-size: $f-girot-font-size-2;
  }

  &__description_wrapper {
    font-size: $f-girot-font-size-2;
    margin-bottom: $f-girot-space-xs;
  }

  &__subtitle {
    display: flex;
    flex-direction: row;
    gap: $f-girot-space-xs;
  }

  &__content {
    border-radius: 1px solid black;
    width: 100%;
    @include flexbox;
    flex-direction: column;
    justify-content: center;
  }

  &__select {
    display: flex;
    margin-bottom: $f-girot-space-s;
    @include flexbox;
    & .base-button {
      width: 100%;
    }
  }

  &__generate-btn {
    width: 100%;
  }
}
