@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.verify {
  margin-bottom: 6rem;
}

.button-above-progress-bar {
  display: flex;
  justify-content: right;
  position: fixed;
  bottom: 106px;
  right: $f-girot-space-xl;
}
