@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin pgl-rule-drawer {
}

.pgl-rule-drawer {
  @include flexbox;
  flex-direction: column;
  height: auto;
  margin-block: $f-girot-space-xs;
  gap: $f-girot-space-s;

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $f-girot-space-s;

    button {
      width: 100%;
    }
  }

  &__navigation {
    text-align: left;
    gap: $f-girot-space-s;
  }
}
