@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.calculations-overview-section {
  display: flex;
  flex-direction: column;

  &__boxes {
    display: grid;
    grid-template-columns: repeat(3, 3fr) 1fr;
    gap: $f-girot-space-xs;
  }

  &__box {
    box-sizing: border-box;
    display: flex;
    width: 100%;
  }

  &__box-label.body2 {
    color: $f-girot-neutral-neutral-300;
  }

  &__value {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__locked-sum {
    box-sizing: border-box;
    display: flex;
    gap: $f-girot-space-xs;
    justify-content: flex-start;
    align-items: flex-end;
  }

  &__previous-sum {
    color: $f-girot-neutral-neutral-300;
  }
}
