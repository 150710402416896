@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin policy-tabs {
}

.policy-tabs {
  &--groups-tab {
    @include flexbox;
    margin-top: $f-girot-space-l;
    flex-direction: row;
    flex-wrap: wrap;
    gap: $f-girot-space-l;
  }
}
