@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin date-filter {
  display: flex;
  flex-direction: row;
  gap: $f-girot-space-l;
}

.date-filter {
  @include date-filter;
  margin-bottom: $f-girot-space-m;

  &--left {
    justify-content: flex-start;
  }

  &--right {
    justify-content: flex-end;
  }

  &__period {
    display: flex;
    flex-direction: column;
    gap: $f-girot-space-xs;
    flex-direction: column;
    &__selects {
      display: flex;
      flex-direction: row;
      gap: $f-girot-space-xs;
    }
  }

  &__label {
    color: $f-girot-neutral-neutral-500;
  }
}
