@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.insurance-event-comment {
  display: grid;
  grid-template-columns: auto 1fr;
  place-items: end;
  gap: $f-girot-space-s;

  &__pill {
    grid-column: 1 / -1;
    place-self: start;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: $f-girot-space-xxs;
  }

  &__info {
    display: flex;
    justify-content: space-between;
  }

  &__name,
  &__date {
    color: $f-girot-neutral-neutral-300;
  }
}
