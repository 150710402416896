@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.yearly-overview {
  &__month {
    & .drawer-navigation__label {
      width: 100%;

      & > * {
        width: 100%;
      }
    }
  }

  &__label {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &-text {
      display: flex;
      align-items: center;
      gap: $f-girot-space-xxs;
      padding-right: 1rem;
    }
  }

  &__icon {
    width: 20px;
    height: 20px;
  }
}
