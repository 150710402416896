@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin employment-details-overview-tab {
  @include flexbox;
  flex-direction: column;
  gap: $f-girot-space-xs;
  margin-top: $f-girot-space-s;
}

.employment-details-overview-tab {
  @include employment-details-overview-tab;
  margin-bottom: $f-girot-space-s;

  &__label-with-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $f-girot-space-xs;
  }

  &__label {
    color: $f-girot-neutral-neutral-600;
  }

  & svg.employment-details-overview-tab__info-icon {
    width: 16px;
    height: 16px;

    & path {
      stroke: $f-girot-neutral-neutral-300;
    }
  }
}

.editable-employment-details {
  display: flex;
  flex-direction: column;
  gap: $f-girot-space-s;
}
