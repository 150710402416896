@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.payment-transactions-details {
  display: flex;
  flex-direction: column;

  &__button {
    flex: 1;
  }

  &__buttons {
    align-items: center;
    margin-top: $f-girot-space-s;
    justify-content: center;
    display: flex;
    gap: $f-girot-space-s;
  }
}
