@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.absence-details {
  &__header {
    display: flex;
    align-items: center;
    gap: $f-girot-space-xs;
  }

  &__heading {
    color: $f-girot-neutral-neutral-500;
  }

  &__content {
    border-top: 1px solid $f-girot-neutral-neutral-30;
    margin-top: $f-girot-space-s;
    padding-top: $f-girot-space-xs;
  }
}
