@import 'src/styles/variables';
@import 'src/styles/global-alias';
@import 'src/styles/mixins';

@mixin period-card {
}

.period-card {
  @include flexbox;
  flex: 1 1 400px;
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
  border-radius: $f-girot-border-radius-m;
  background-color: $white;
  transition: background-color 0.2s ease-in-out;
  width: 100%;
  max-width: 600px;

  &:hover {
    background-color: $f-girot-neutral-neutral-20;
  }

  &__content {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: $f-girot-space-xs;
    padding: $f-girot-space-s;
    width: 100%;
  }

  &__summary-data {
    position: relative;
  }

  &--title-header {
    @include flexbox;
    box-sizing: border-box;
    justify-content: flex-start;
    align-items: center;
    column-gap: $f-girot-space-xxs;
    padding: $f-girot-space-s $f-girot-space-s 0 $f-girot-space-s;
    width: 100%;
  }

  &--title-year {
    color: $f-girot-neutral-neutral-50;
  }

  &--title-pill {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }

  &--box-row {
    @include flexbox;
    justify-content: flex-start;
    column-gap: $f-girot-space-xxs;
  }

  &--buttons {
    margin: 0 auto;
    display: flex;
    gap: $f-girot-space-xs;
    width: 100%;

    & button {
      flex: 1;
    }
  }

  &--sub-title {
    color: $f-girot-neutral-neutral-100;
  }

  &--summary {
    box-sizing: border-box;
    margin: 0 $f-girot-space-m;
    padding: $f-girot-space-s;
    border-radius: $f-girot-border-radius-m;
    background-color: $f-girot-neutral-neutral-10;
    display: flex;
    flex-direction: column;
    margin-bottom: $f-girot-space-s;
  }
}
