@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.employee-comments-drawer {
  margin-top: $f-girot-space-xxl;

  &__comments {
    margin-bottom: 20px;
  }

  &__add-comments {
    margin-top: $f-girot-space-m;
    .input-area {
      height: 7rem;
    }
  }
}
