@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.multiple-employment-details {
  display: flex;
  gap: $f-girot-space-xs;

  &__button {
    display: flex;
    align-items: center;
    gap: $f-girot-space-xs;
    background-color: transparent;
    border: none;
    cursor: pointer;
    border-radius: $f-girot-border-radius-l;
    padding-inline: $f-girot-space-xs;

    & span {
      color: $f-girot-green-green-500;
    }

    & svg {
      width: 16px;
      height: 16px;

      & path {
        stroke: $f-girot-green-green-500;
      }
    }

    &--toggled {
      & svg {
        transform: rotate(90deg);
      }
    }

    &:hover {
      background-color: $f-girot-neutral-neutral-20;

      & span {
        color: $f-girot-green-green-600;
      }
    }
  }

  &__employments {
    display: flex;
    flex-direction: column;
  }
}
