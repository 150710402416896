@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.unpaid-invoices-overview-section {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 800px;
  border-radius: $f-girot-border-radius-m;
  position: relative;
  width: 100%;
  overflow: scroll;

  &__list {
    display: flex;
    flex-direction: column;
    gap: $f-girot-space-xs;
    margin-right: $f-girot-space-xs;
  }
}
