@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.calculations-about-drawer-tab {
  margin-top: $f-girot-space-s;

  & .list-group--drawer-navigation {
    margin-bottom: $f-girot-space-m;
  }

  &__heading {
    padding-bottom: $f-girot-space-xs;
  }

  &__locked-sum {
    box-sizing: border-box;
    display: flex;
    gap: $f-girot-space-xs;
    justify-content: flex-start;
    align-items: flex-end;
  }
}
