@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.select-customer {
  display: flex;
  flex-direction: column;
  
  &__header{
    display: flex;
    justify-content: flex-end;
    margin-top: $f-girot-space-s;
    margin-right: $f-girot-space-s;
    margin-bottom: 20%;
  }
  &__content{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__wrapper {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $f-girot-space-s;
  }
  &__filter-customers{
    width: 100%;
  }
}
