@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin employment-overview-section {
  @include flexbox;
  flex-direction: column;
  min-width: 200px;
  padding: $f-girot-space-s;
  border-radius: $f-girot-border-radius-m;
  background-color: $white;
  border: 2px solid transparent;
  position: relative;
}

.employment-overview-section {
  @include employment-overview-section;

  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: $f-girot-space-xs;
  }

  &__property {
    list-style: none;
    display: flex;
    flex-direction: column;
    width: fit-content;
  }

  &__property-icon {
    width: 20px;
    height: 20px;
  }

  &__property-label {
    color: $f-girot-neutral-neutral-300;
  }

  &__property-text {
    color: $f-girot-neutral-neutral-700;
  }
  &__property-value {
    display: flex;
    gap: $f-girot-space-xxs;
    align-items: center;
  }
}
