@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.multiple-monthly-salary-fromdate {
  display: flex;
  flex-direction: column;
  gap: $f-girot-space-s;

  &__period-header {
    display: flex;
    align-items: center;
    gap: $f-girot-space-xs;
  }

  &__wage-type-row-section {
    margin-bottom: $f-girot-space-xs;
  }

  & .inline-edit__children {
    & span {
      margin-left: auto;
    }
  }
  &__amount-text {
    text-wrap: nowrap;
  }
}
