@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.accounting-items-tab {
  margin-top: $f-girot-space-l;
  &__export-container {
    display: flex;
    align-items: center;
    margin-top: $f-girot-space-xs;
    margin-bottom: $f-girot-space-xs;

    &__button {
      margin-right: $f-girot-space-xs;
    }
  }

  &__table-buttons {
    margin-top: $f-girot-space-m;
    display: flex;
    justify-content: flex-end;
    gap: $f-girot-space-xs;
  }

  &__filter-button {
    display: flex;
    justify-content: right;
    position: fixed;
    bottom: $f-girot-space-xl;
    right: $f-girot-space-xl;
  }

  &__pagination {
    margin-top: $f-girot-space-xs;
  }

  &__table {
    &__body {
      &__loading {
        position: absolute;
        right: 50%;
        top: 50%;
      }
    }
  }
}
