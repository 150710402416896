@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.editable-property {
  &__label {
    color: $f-girot-neutral-neutral-600;
    display: flex;
    align-items: center;
    gap: $f-girot-space-xxs;

    & span {
      color: inherit;
    }
  }

  &__icon {
    width: 20px;
    height: 20px;
  }
}
