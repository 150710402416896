@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.missing-employee-in-wage-file {
  &__inline-info-message {
    display: flex;
    flex-direction: column;
    margin-top: $f-girot-space-xs;
  }

  &__info-message {
    margin-left: $f-girot-space-s;
  }
}
