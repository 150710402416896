@import 'src/styles/variables';
@import 'src/styles/global-alias';
@import 'src/styles/mixins';

.period-details-card {
  background-color: $white;
  padding: $f-girot-space-m;
  border-radius: $f-girot-border-radius-m;
  display: flex;
  flex-direction: column;
  gap: $f-girot-space-m;
  margin-bottom: $f-girot-space-l;

  &--pie-chart {
    width: 100%;
    max-width: 500px;
    border-radius: $f-girot-border-radius-m;
    background-color: $f-girot-neutral-neutral-20;
    position: relative;
  }

  &--title {
    display: flex;
    gap: $f-girot-space-xs;
    flex: 1;
    height: fit-content;
    align-items: center;

    &-pill {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }

  &--header {
    display: flex;
    justify-content: space-between;
    gap: $f-girot-space-m;
    flex-wrap: wrap;
  }

  &--content {
    display: flex;
    gap: $f-girot-space-m;
    flex-wrap: wrap;
  }

  &--section {
    box-sizing: border-box;
    flex: 1 1 12rem;
    min-height: 16rem;
    max-width: 32rem;
    background-color: $f-girot-neutral-neutral-20;
    border-radius: $f-girot-border-radius-m;
    padding: $f-girot-space-m;
    display: flex;
    flex-direction: column;
    gap: $f-girot-space-s;
    position: relative;

    & button {
      margin-top: auto;
    }
  }

  &--section-content {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: $f-girot-space-xs;
  }

  &--info-box {
    background-color: $f-girot-neutral-neutral-10;
    border-radius: $f-girot-border-radius-s;
    display: flex;
    gap: $f-girot-space-xs;
    align-items: center;
    padding: $f-girot-space-xs;

    &-icon {
      aspect-ratio: 1;
      width: 1.75rem;
      height: 1.75rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      background-color: $f-girot-neutral-neutral-30;

      & svg {
        width: 1rem;

        & path {
          stroke: $f-girot-neutral-neutral-100;
        }
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
    }
  }

  &--section-title {
    color: $f-girot-neutral-neutral-400;
  }

  &--info-box-title {
    color: $f-girot-neutral-neutral-300;
  }

  &--info-box-value {
    color: $f-girot-neutral-neutral-300;
  }
}
