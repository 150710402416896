@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin rule-type-drawer {
}

.rule-type-drawer {
  &__list-group {
    margin-top: $f-girot-space-m;
  }

  &__list-group-collapsible {
    margin-top: $f-girot-space-xxs;
  }
}
