@import './src/styles/_variables.scss';

$white: $f-girot-neutral-neutral-0;
$black: $f-girot-neutral-neutral-900;
$dark-grey: $f-girot-neutral-neutral-700;

$disabled-color: $f-girot-neutral-neutral-50;
$disabled-background-color: $f-girot-neutral-neutral-20;

$alert-color: $f-girot-red-red-500;
$success-color: $f-girot-green-green-500;

$overlay-green-4: rgba(20, 80, 74, 0.04);
$overlay-green-8: rgba(20, 80, 74, 0.08);
$overlay-green-24: rgba(46, 122, 115, 0.24);

$overlay-grey-4: rgba(9, 30, 66, 0.04);
$overlay-grey-8: rgba(9, 30, 66, 0.08);
$overlay-grey-16: rgba(9, 30, 66, 0.16);

$overlay-black: rgba(9, 30, 66, 0.5);

$z-index-10: 10;
$z-index-20: 20;
