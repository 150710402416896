@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.employee-checkpoint-details {
  &__header {
    padding-bottom: $f-girot-space-xs;
  }

  &__subtitle {
    margin-bottom: $f-girot-space-xs;
  }

  &__pill {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: $f-girot-space-xs;
  }
}
