@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.error-message {
  @include flexbox;
  vertical-align: middle;
  display: flex;
  margin: $f-girot-space-xs 0;

  &__content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  & svg.error-message__icon {
    margin-right: $f-girot-space-xs;
    width: 24px;
    min-width: max-content;
    height: 24px;

    & path {
      stroke: $f-girot-red-red-600;
    }
  }
}
.error-message__text {
  color: $f-girot-red-red-600;
}
