@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.generate-document-drawer-tab {
  margin-top: $f-girot-space-xs;
  display: flex;
  flex-direction: column;
  gap: $f-girot-space-s;
}
