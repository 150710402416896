@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.insurance-events-tab {
  margin-top: $f-girot-space-s;

  &__event-label {
    display: flex;
    align-items: center;
    text-align: left;
    gap: $f-girot-space-xs;
  }

  &__employee-header {
    display: flex;
    align-items: center;
    gap: $f-girot-space-xs;
  }
}
