@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.employee-absence-overview {
  &__filters {
    display: flex;
    gap: $f-girot-space-s;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: $f-girot-space-s;

    & .date-filter {
      padding: 0;
      margin: 0;
    }
  }

  &__wage-type-filter {
    & .select--label {
      font-family: OpenSansBold;
      color: $f-girot-neutral-neutral-500;
      line-height: 1;
    }

    & .select {
      margin-top: $f-girot-space-xxs;
    }
  }
}
