@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin pgl-rules-table-row {
}

.pgl-rules-table-row {
  height: auto;
}
