@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.verify-drawer-checkpoints-tab {
  &__drawer-container {
    @include flexbox;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    gap: $f-girot-space-xs;
    margin-top: $f-girot-space-s;
  }

  &__checkpoint-wrapper {
    margin-bottom: $f-girot-space-l;
    display: flex;
    flex-direction: column;
    gap: $f-girot-space-xxs;
  }
}
