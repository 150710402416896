@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin policy-versions-drawer {
}

.policy-versions-drawer {
  height: auto;
  display: flex;

  &--list-group {
    margin: 25px 0px;
  }
  &__title {
    display: flex;
    &--pill {
      margin: 4px;
    }
    &--icon {
      margin: 4px;
    }
    &--typography {
      margin: 4px;
    }
  }
}
