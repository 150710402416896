@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin policy-benefit-table-row {
}

.policy-benefit-table-row {
  height: auto;
}
