@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.create-user-modal {
  &__radio-group {
    margin-bottom: $f-girot-space-xxs;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $f-girot-space-s;
    margin-bottom: $f-girot-space-s;
  }
  
  &__identifier {
    margin-bottom: $f-girot-space-s;
  }

  &__reason {
    margin-bottom: $f-girot-space-s;
  }

  &__customer-select {
    width: 100%;
  }
}
