@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin policy-benefits {
}

.policy-benefits {
  margin-top: $f-girot-space-l;
}
