@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.retroactive-new-registration-checkpoint {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $f-girot-space-xs;
}
