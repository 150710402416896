@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin certify-employee-drawer {
}

.certify-employee-drawer {
  height: auto;
}
