@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.td-full-name {
  display: flex;
  align-items: center;
  gap: $f-girot-space-xs;
  flex-wrap: wrap;
}
