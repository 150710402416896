@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.multiple-employment-details-row {
  border-radius: $f-girot-border-radius-l;
  background-color: $f-girot-neutral-neutral-20;
  display: flex;
  align-items: center;
  margin-top: $f-girot-space-xs;

  &__details {
    display: flex;
    align-items: center;
    gap: $f-girot-space-xs;
    flex: 1;
  }

  &__button {
    border: none;
    margin-right: $f-girot-space-s;
    background-color: transparent;
    cursor: pointer;
    border-radius: $f-girot-border-radius-m;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $f-girot-space-xs;

    & svg {
      & path {
        stroke: $f-girot-neutral-neutral-80;
      }
    }

    &:hover {
      background-color: $f-girot-neutral-neutral-30;

      & svg {
        & path {
          stroke: $f-girot-neutral-neutral-200;
        }
      }
    }
  }

  &__sr-only {
    @include sr-only;
  }
}
