@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.employee-actions {
  margin-bottom: $f-girot-space-s;
  margin-top: $f-girot-space-xs;

  &__header {
    display: flex;
    gap: $f-girot-space-xs;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $f-girot-space-s;
    flex-wrap: wrap;
  }

  &__buttons {
    display: flex;
    gap: $f-girot-space-xs;
  }

  &__button {
    padding: 6px 16px 6px 6px;

    & .button__icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__icon {
    width: 18px;
    height: 18px;
  }
}
