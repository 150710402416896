@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.kub-details-drawer {
  & .list-group--inline-edit {
    margin-bottom: $f-girot-space-m;
  }

  & .inline-edit__children {
    text-align: right;
  }

  &__label {
    display: flex;
    align-items: baseline;
    gap: $f-girot-space-xxs;

    &--text {
      color: $f-girot-neutral-neutral-600;
    }
  }

  &--locked-sum {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: $f-girot-space-xs;
  }

  &--text {
    margin-left: $f-girot-space-xs;
  }
}
