@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';
@mixin policy-employment-group-drawer {
}
.policy-employment-group-drawer {
  &__tabs {
    margin-top: $f-girot-space-m;
    &__tab {
      margin-top: $f-girot-space-m;
    }
    &__or-container {
      margin-top: $f-girot-space-m;
      text-align: center;
      display: flex;
      justify-content: center;
      &__text {
        color: $f-girot-neutral-neutral-400;
      }
      &__button {
        width: 100%;
        &--icon {
          path {
            fill: $f-girot-neutral-neutral-0;
          }
        }
      }
    }
    &__rule-list-group {
      margin-top: $f-girot-space-m;
    }
  }
  &__pill {
    display: flex;
    justify-content: flex-start;
    gap: $f-girot-space-xxs;
  }
}
