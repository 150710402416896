@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.add-employer-individual-choice-modal {
  &__inputs {
    display: flex;
    flex-direction: column;
    gap: $f-girot-space-s;
  }

  &__switches {
    display: flex;
    align-items: center;
    gap: $f-girot-space-m;
  }

  &__switch {
    display: flex;
    flex-direction: column;
    gap: $f-girot-space-xs;

    & span.caption {
      white-space: nowrap;
    }
  }
}
