@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.accounting-type {
  display: flex;
  flex-direction: column;
  gap: $f-girot-space-m;

  & * {
    box-sizing: border-box;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: $f-girot-space-xs;
  }

  &__icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $f-girot-neutral-neutral-30;

    & svg {
      width: 16px;
      height: 16px;

      & path {
        stroke: $f-girot-neutral-neutral-90;
      }
    }
  }
  &__accounting-component-accordion {
    margin: $f-girot-space-xs;
    display: flex;
    flex-direction: column;
    &--header {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: $f-girot-space-s;
      flex-direction: row;
      &--share-error {
        display: flex;
        gap: $f-girot-space-xs;
        align-items: center;

        &--text {
          color: #ae2336;
        }
        &--icon path {
          stroke: #ae2336;
        }
      }
    }
    &--text {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding-right: 15px;
    }
    &__body {
      background-color: $f-girot-neutral-neutral-20;
      border-radius: $f-girot-border-radius-s;
      padding: $f-girot-space-xs;
      display: flex;
      flex-direction: row;
      &--component {
        width: 100%;
      }
    }
  }

  &__accounting-component-list {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: $f-girot-space-xs;
    align-items: stretch;
  }

  &__accounting-component {
    display: flex;
    flex-direction: column;
    min-width: fit-content;
    padding: $f-girot-space-s;
    gap: $f-girot-space-xs;
    background-color: $f-girot-neutral-neutral-20;
    border-radius: $f-girot-border-radius-m;
    flex: 1 1 0;
    width: 0;

    &--title {
      display: flex;
      align-items: center;
      gap: $f-girot-space-xs;
      flex-wrap: wrap;
    }
  }

  &__accounting-component-list {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    gap: $f-girot-space-s;
    flex-direction: row;
    border-radius: $f-girot-border-radius-s;
  }

  &__accounting-component {
    min-width: min-content;
    padding: $f-girot-space-xs;
    display: flex;
    flex-direction: column;
    gap: $f-girot-space-xxs;
    border-radius: $f-girot-border-radius-s;
    flex: 1 1 0;
    width: 0;
  }
}

.accounting-type-page {
  &__title {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  &__container {
    margin-top: $f-girot-space-m;
    padding: $f-girot-space-m;
    background: $f-girot-neutral-neutral-30;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    &--share-banner {
      margin: $f-girot-space-m 0;
    }
    &__edit {
      display: flex;
      margin-top: $f-girot-space-m;
      margin-bottom: $f-girot-space-xs;
      &--switch {
        display: flex;
        flex: 1;
        flex-direction: column;
      }
      &--buttons {
        display: flex;
        flex-direction: row;
        gap: $f-girot-space-s;
      }
    }
  }
  &__cost-center-accordion {
    margin: 10px;
  }
  &__accounting-component-share-input {
    width: 25%;
  }
  &__cost-center-content {
    display: flex;
    flex-direction: column;
    margin-top: $f-girot-space-xs;
  }
  &__accounting-components {
    display: flex;
    flex-direction: 'row';
    flex-flow: wrap;
    &--item {
      width: 100%;
      display: flex;
      flex-direction: row;
      background-color: $f-girot-neutral-neutral-20;
      border-radius: 16px;
      justify-content: space-between;
      margin: 10px;
      padding: 10px;
      &--select {
        margin: 20px;
        width: 200px;
      }
      &--delete-button {
        margin: 20px;
      }
    }
  }
}
