@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.benefits-overview-drawer {
  margin-top: $f-girot-space-s;

  &__list-item-label {
    display: flex;
    align-items: center;
    gap: $f-girot-space-xs;
  }
}
