@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.users-tab {
  width: 100%;
  &__create-user-modal-button {
    display: flex;
    justify-content: right;
    position: fixed;
    bottom: $f-girot-space-xl;
    right: $f-girot-space-xl;
  }
}
