@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.insurance-event-details-drawer {
  &__sub-title {
    display: flex;
    align-items: center;
    gap: $f-girot-space-xs;
    margin-top: $f-girot-space-xs;
  }
}
