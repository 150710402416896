@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin policy-changes-drawer {
}

.policy-changes-drawer {
  &--sub-title {
    margin: 1.5rem 0rem;

    &__typography {
      color: $f-girot-neutral-neutral-300;
    }
  }

  &__inlineEdit {
    &--label {
      @include flexbox;

      &__pill {
        margin-left: 0.5rem;
      }
    }
    &__typography {
      margin: 0 0.625rem;
      color: $f-girot-neutral-neutral-300;
    }
  }
}
