@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.validation-error-modal {
  &__icon {
    path {
      stroke: $f-girot-orange-orange-500;
    }
  }

  &__error-section {
    max-height: 24rem;
    overflow-y: scroll;
  }

  &__divider {
    margin: 24px 0;

    &__title {
      margin-bottom: 8px;
    }

    &__list {
      list-style-type: disc;
      margin-left: 16px;

      * {
        color: $f-girot-neutral-neutral-900;
      }
    }
  }

  &__faq {
    margin-top: 16px;
  }
}
