@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.absence-wage-type-details-drawer {
  display: flex;
  flex-direction: column;
  gap: $f-girot-space-s;

  &__total {
    & .inline-edit__children span {
      width: 100%;
      text-align: right;
      margin-right: 24px;
    }
  }
}
