@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin accounting-components-overview-tab {
  @include flexbox;
  flex-direction: column;
  gap: $f-girot-space-xs;
  margin-top: $f-girot-space-s;
}

.accounting-components-overview-tab {
  @include accounting-components-overview-tab;

  &__headings {
    display: flex;
    flex-direction: row;
    padding-bottom: $f-girot-space-xs;
  }

  &__right-heading {
    @media (max-width: 3440px) and (min-width: 800px) {
      padding-left: calc(2px + (100vw - 800px) * (2 - 388) / (800 - 3440));
    }
  }

  &__list {
    margin-bottom: $f-girot-space-m;
  }
}
