@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin validating {
}

.validating {
  &__banner-container {
    margin-top: 32px;
  }

  &__banner {
    margin-top: 16px;
    svg {
      path {
        stroke: $white;
      }
    }
  }

  &__filter {
    @include flexbox;
    align-items: center;
    margin-top: 32px;

    &__search {
      width: auto !important;
      flex-grow: 2;
    }

    &__dropdowns {
      @include flexbox;
    }

    &__dropdown {
      margin-left: 16px;
    }
  }
}
