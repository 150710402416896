@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.disable-automatic-reporting-banner {
  position: fixed;
  top: 0;
  right: 0.5rem;
  z-index: $z-index-20;
  max-width: fit-content;
}
