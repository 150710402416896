@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.employee-missing-modal {
  display: flex;
  flex-direction: column;
  max-height: 100%;

  &__container {
    margin-top: $f-girot-space-xs;
    display: flex;
    flex-direction: column;
    gap: $f-girot-space-xs;
    height: 600px;
    max-height: 100%;
    overflow: scroll;
  }

  .employee-list-item {
    margin-right: $f-girot-space-xs;
  }
}
