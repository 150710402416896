@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.invoice-header {
  margin-top: 10px;
  margin-bottom: 10px;

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__property {
    flex-basis: calc(33% - 10px);
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    height: 36px;
    margin-bottom: $f-girot-space-s;

    &--button {
      white-space: nowrap;
      margin: 0px 4px;
      padding: 8px 16px;
    }
  }
}
