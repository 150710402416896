@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.wages-overview-section {
  display: grid;
  grid-template-columns: repeat(3, 3fr) 1fr;
  gap: $f-girot-space-xs;

  &__text-label.body2 {
    color: $f-girot-neutral-neutral-300;
  }
}
