@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.bonus-overview-drawer {
  display: flex;
  flex-direction: column;
  gap: $f-girot-space-s;

  & .inline-edit__children {
    text-align: right;
  }
}
