@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.policy-comments {
  &__comments {
    margin-bottom: 20px;
  }
  &__add-comments {
    .input-area {
      height: 7rem;
    }
  }
}
