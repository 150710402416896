@import 'styles/normalize.scss';
@import 'styles/fonts.scss';
@import './src/styles/_variables.scss';

@mixin container {
  background: $f-girot-neutral-neutral-20;
  min-height: 100vh;
}

body {
  background: $f-girot-neutral-neutral-20;
  font-size: 16px;
}

.page-container {
  @include container;
  padding-left: 8rem;
}

.flex {
  display: flex;
}
::-webkit-scrollbar {
  width: 0.5rem;
  height: 0;
  background-color: $f-girot-neutral-neutral-0;
}

::-webkit-scrollbar-thumb {
  background: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4.5C0.723858 4.5 0.5 4.72386 0.5 5C0.5 5.27614 0.723858 5.5 1 5.5L1 4.5ZM5 5.5C5.27614 5.5 5.5 5.27614 5.5 5C5.5 4.72386 5.27614 4.5 5 4.5L5 5.5ZM1 5.5L5 5.5L5 4.5L1 4.5L1 5.5Z' fill='%238993A4'/%3E%3Cpath d='M1 2.5C0.723858 2.5 0.5 2.72386 0.5 3C0.5 3.27614 0.723858 3.5 1 3.5L1 2.5ZM5 3.5C5.27614 3.5 5.5 3.27614 5.5 3C5.5 2.72386 5.27614 2.5 5 2.5L5 3.5ZM1 3.5L5 3.5L5 2.5L1 2.5L1 3.5Z' fill='%238993A4'/%3E%3Cpath d='M1 0.5C0.723858 0.5 0.5 0.723858 0.5 1C0.5 1.27614 0.723858 1.5 1 1.5L1 0.5ZM5 1.5C5.27614 1.5 5.5 1.27614 5.5 1C5.5 0.723858 5.27614 0.5 5 0.5L5 1.5ZM1 1.5L5 1.5L5 0.5L1 0.5L1 1.5Z' fill='%238993A4'/%3E%3C/svg%3E")
    $f-girot-neutral-neutral-40;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: $f-girot-border-radius-s;
}
