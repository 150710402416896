@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin policy-version-drawer {
}

.policy-version-drawer {
  &__go-to-version {
    margin: 24px 0px;
  }
}
