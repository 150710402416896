@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.absence-table-head {
  & th {
    & div.absence-table-head__absence-days {
      display: block;
      text-align: right;
    }
  }
}
