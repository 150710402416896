@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.absence-table-row {
  & td {
    & div.absence-table-row__sum {
      display: block;
      text-align: right;
    }
  }
}
