@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.missing-pay-types-banner {
  height: auto;

  & .banner__icon {
    & path {
      stroke: $f-girot-neutral-neutral-20;
    }
  }
}
