@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin reporting-settings {
}

.reporting-settings {
  &__tabs {
    margin-top: $f-girot-space-l;
  }
}
