@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

div.pay-type-mapping-drawer {
  &__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $f-girot-space-s;
    width: 100%;
    margin-right: $f-girot-space-s;
  }
}
