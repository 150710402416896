@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin progress-with-error-modal {
}

.progress-with-error-modal {
  &__text {
    &--margin {
      margin-top: 24px;
    }
  }
}
