@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin wage-limit-rule-group {
}

.wage-limit-rule-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: $f-girot-space-s;

  &__buttons {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: $f-girot-space-s;
  }
}
