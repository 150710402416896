@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin reporting-progress-bar {
  position: fixed;
  right: 0;
  left: 8rem;
  bottom: 0;
  max-width: calc(100% - 8rem);
}

.reporting-progress-bar {
  @include reporting-progress-bar;
}
