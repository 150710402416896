@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.draft-changed-tooltip {
  display: flex;
  align-items: center;
  gap: $f-girot-space-xxs;

  & .tooltip__wrapper {
    display: flex;
    align-items: center;
    gap: $f-girot-space-xxs;
  }
}
