@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.supplier {
  &__title {
    color: $f-girot-neutral-neutral-700;
    margin-bottom: $f-girot-space-s;
  }
}
