@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.certify-conditions-modal {
  &__content {
    margin-top: $f-girot-space-l;
  }

  &__card {
    &__icon {
      path {
        stroke: $f-girot-blue-blue-500;
      }
    }
  }
}
