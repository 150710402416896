@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.insurance-event-comments-tab {
  &__comments {
    display: flex;
    flex-direction: column;
    gap: $f-girot-space-s;
    margin-top: $f-girot-space-s;
  }

  &__comment {
    display: flex;
    flex-direction: column;
    gap: $f-girot-space-s;
  }
}
