@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin company-upload-status {
}

.company-upload-status {
  &__drawer {
    height: 100vh;
    box-sizing: border-box;
  }

  &__subtitle {
    color: $f-girot-neutral-neutral-300;
  }

  &__file-info {
    @include flexbox;
    flex-direction: column;
    margin-top: 24px;
  }

  &__files {
    &__container {
      margin-top: 24px;
    }
    &__header {
      @include flexbox;
      align-items: center;
      justify-content: space-between;

      &__icon {
        height: 16px;
        width: 16px;
        path {
          stroke: $f-girot-green-green-400;
        }
      }
    }
    &__cards {
      &__card {
        margin-top: 8px;
      }
    }
  }
}
