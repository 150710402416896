@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.reporting-event-details-row {
  &__status {
    display: flex;
    align-items: baseline;
    gap: $f-girot-space-xs;
  }

  &__calculations {
    display: flex;
    flex-direction: column;
    gap: $f-girot-space-xxs;

    &--details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: $f-girot-space-xs;
    }
  }
}
