@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.period-change-button {
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  width: 36px;
  height: 36px;
  background-color: $f-girot-neutral-neutral-30;
  transition: all 0.1s ease-in-out;

  &--previous {
    transform: rotate(180deg);
  }

  & svg {
    & path {
      stroke: $f-girot-neutral-neutral-300;
    }
  }

  &:hover {
    background-color: $f-girot-neutral-neutral-40;
  }

  &__sr-only {
    @include sr-only;
  }
}
