@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.calculation-lock-details {
  margin-top: $f-girot-space-s;
  display: flex;
  flex-direction: column;

  &__period-list-group {
    margin-top: $f-girot-space-s;
  }

  &__buttons {
    margin-top: $f-girot-space-s;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $f-girot-space-xs;
    flex-direction: row;
  }

  &__period-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    justify-content: space-between;
  }
}
