@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.employee-overview {
  &__info-banner {
    margin-bottom: $f-girot-space-m;
  }
  &__cards {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: $f-girot-space-m;
  }

  &__card-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: $f-girot-space-m;
  }

  &__overview {
    background-color: $f-girot-neutral-neutral-0;
    border-radius: $f-girot-border-radius-l;
    padding: $f-girot-space-s;
    display: flex;
    flex-direction: column;
    gap: $f-girot-space-xs;
  }

  &__overview-label {
    color: $f-girot-neutral-neutral-300;
  }

  &__overview-value {
    color: $f-girot-neutral-neutral-700;
  }
  &__employment-properties {
    display: flex;
    flex-direction: column;
  }
}
