@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';


.container {
  padding-bottom: $f-girot-space-xs;
}

.srOnly {
  @include sr-only;
}

.listIcon {
  padding-right: $f-girot-space-xs;
}