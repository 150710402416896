@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.employee-insurance-event-details-drawer {
  &__title-pills {
    margin-top: $f-girot-space-xs;
    display: flex;
    align-items: center;
    gap: $f-girot-space-xs;
  }
}
