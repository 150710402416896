@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.kub-drawer {
  &__content {
    margin-top: $f-girot-space-m;
    display: flex;
    flex-direction: column;
    gap: $f-girot-space-m;
  }
  &__description-text {
    color: $f-girot-neutral-neutral-300;
    font-size: $f-girot-font-size-0 + px;
  }
}
