@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.calculation-label {
  display: flex;
  align-items: center;
  gap: $f-girot-space-xs;

  &__label-color {
    color: $f-girot-neutral-neutral-300;
  }
}
