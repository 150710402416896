@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.employee-tab {
  &__label {
    color: $f-girot-neutral-neutral-400;
    margin: $f-girot-space-s 0 $f-girot-space-m;
  }

  &__activeFilters {
    color: $f-girot-neutral-neutral-400;
    margin: $f-girot-space-s 0 $f-girot-space-m;
    :hover {
      cursor: pointer;
    }
  }

  &__search-results {
    margin-top: $f-girot-space-s;
  }

  &__label-row {
    @include flexbox;
    justify-content: space-between;
  }

  .message--alert {
    color: $f-girot-red-red-600;
  }
  &__filter-button {
    display: flex;
    justify-content: right;
    position: fixed;
    bottom: $f-girot-space-xl;
    right: $f-girot-space-xl;
  }
}
