@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.individual-choice-retirement-age-rule {
  margin-top: $f-girot-space-s;

  &__collapsible {
    margin-top: $f-girot-space-xxs;
  }
}
