@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin file-upload-container {
  @include flexbox;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: $f-girot-space-m;
  margin-top: $f-girot-space-xl;

  height: 20rem;

  background: $white;
  border-radius: $f-girot-border-radius-m;
}

@mixin file-upload-divider {
  @include flexbox;
  align-items: center;
  width: 100%;
  margin-top: $f-girot-space-l;
  margin-bottom: $f-girot-space-s;

  &--divider {
    justify-content: center;
    background-color: $f-girot-neutral-neutral-40;
  }

  &--text {
    justify-content: center;
    color: $f-girot-neutral-neutral-300 !important;
    margin: 0 $f-girot-space-s;
  }

  &--or {
    justify-content: center;
    color: $f-girot-neutral-neutral-100 !important;
    margin: 0 $f-girot-space-s;
  }
}
@mixin file-upload-text {
  display: inline-block;
}

@mixin file-upload {
  background-color: $f-girot-neutral-neutral-20;
  border: 1px dashed $f-girot-neutral-neutral-50;

  &--text {
    color: $f-girot-neutral-neutral-300 !important;
  }
}

.file-upload-container {
  @include file-upload-container;
}
.file-upload-divider {
  @include file-upload-divider;
}
.file-upload {
  @include file-upload;

  &__drag-text {
    @include flexbox;
  }
}
