@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.pgl-details-drawer {
  display: flex;
  flex-direction: column;
  gap: $f-girot-space-s;

  & .inline-edit__children {
    text-align: right;
  }

  &__drawer-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: $f-girot-space-s;
  }

  &--locked-sum {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: $f-girot-space-xs;
  }

  &--text {
    margin-left: $f-girot-space-xs;
  }
}
