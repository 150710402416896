@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin employment-control-point-row {
}

.employment-control-point-row {
  &__pill {
    &--margin {
      margin-left: 4px;
    }
  }
}
