@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.absence-overview-section {
  & .banner__icon {
    path {
      stroke: $f-girot-neutral-neutral-10;
    }
  }
}
