@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin about-supplier-table {
}

.about-supplier-table {
  margin-top: $f-girot-space-m;

  &__info {
    margin-top: $f-girot-space-xs;
    padding: 0 $f-girot-space-s;

    &__text {
      color: $f-girot-neutral-neutral-300;
    }
  }
}
