@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.headerContainer {
  display: flex;
  align-items: center;
  gap: $f-girot-space-s;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
    background-color: $f-girot-neutral-neutral-10;
  }
}

.title {
  display: flex;
  align-items: center;
  gap: $f-girot-space-xxs;
  flex: 1;
}

.icon {
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $f-girot-neutral-neutral-30;

  svg {
    width: 16px;
    height: 16px;

    path {
      stroke: $f-girot-neutral-neutral-90;
    }
  }
}

.pillsOuterContainer {
  display: flex;
  align-items: center;
  gap: $f-girot-space-xs;
}

.pillsInnrContainer {
  display: flex;
  background-color: $f-girot-neutral-neutral-30;
  padding: $f-girot-space-xxs;
  border-radius: $f-girot-border-radius-l;
}


