@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin employment-control-point-settings-table {
}

.employment-control-point-settings-table {
  &__table {
    &__head {
      justify-content: center;
    }
  }
}
