@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.absence-overview-drawer {
  @include flexbox;
  flex-direction: column;
  gap: $f-girot-space-xs;

  &__title {
    display: flex;
    flex-direction: column;
  }

  &__label {
    display: flex;
    align-items: center;
    gap: $f-girot-space-xs;
  }
}
