@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.update-invoice {
  display: flex;
  flex-direction: column;
  margin-bottom: $f-girot-space-s;

  &__note-input {
    margin: 1rem 0rem;
    .input-area {
      height: 16rem;
    }
  }

  &__list-group {
    display: flex;
    flex-direction: column;
    gap: $f-girot-space-s;
  }

  &__button {
    flex: 1;
  }

  &__buttons {
    align-items: center;
    margin-top: $f-girot-space-s;
    justify-content: center;
    display: flex;
    gap: $f-girot-space-s;
  }
}
