@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.invoices-filter-details {
  display: flex;

  span {
    margin: auto 0;
  }
}
