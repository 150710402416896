@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.pillContainer {
  @include flexbox;
  gap: $f-girot-space-xxs;
  flex-wrap: wrap;
}

.labelContainer {
  @include flexbox;
  flex-wrap: wrap;
  gap: $f-girot-space-xs;
}