@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.insurances-filter-details {
  margin-bottom: $f-girot-space-s;

  &__buttons {
    display: flex;
    align-items: center;
    gap: $f-girot-space-xs;
  }
}
