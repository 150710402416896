@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.create-draft-calculation-lock-drawer {
  margin-top: $f-girot-space-s;
  display: flex;
  flex-direction: column;

  &__collapsible-list-group {
    margin-top: $f-girot-space-xxs;
  }

  &__period-list-group {
    margin-top: $f-girot-space-s;
  }

  &__button {
    margin-top: $f-girot-space-s;
  }

  &__period-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    justify-content: space-between;
  }
}
