@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin policy-benefit-drawer {
}

.policy-benefit-drawer {
  &__tab {
    margin-top: $f-girot-space-m;

    &__table {
      margin-top: $f-girot-space-xs;
    }
  }
}

.sub-title-pill {
  display: flex;
  gap: $f-girot-space-xxs;
}

.agreed-products-drawer {
  @include flexbox;

  &__button {
    display: flex;
    align-items: center;
    margin-top: $f-girot-space-s;

    button {
      width: 100%;
    }
  }
}
