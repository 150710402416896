@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.condition-row {
  @include flexbox;
  align-items: center;
  margin-top: $f-girot-space-s;

  &__pill {
    margin-left: $f-girot-space-xs;
  }

  &__checkbox {
    margin-left: $f-girot-space-xs;
  }

  &__text {
    color: $f-girot-neutral-neutral-900;
    margin-left: $f-girot-space-xs;
  }
}
