@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.benefit-details-affiliation-tab {
  display: flex;
  flex-direction: column;
  gap: $f-girot-space-xs;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__value {
    display: flex;
    align-items: center;
    gap: $f-girot-space-xs;
  }
}
