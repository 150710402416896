@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.report {
  @include flexbox;
  height: 95%;
  width: 100%;

  &__container {
    @include flexbox;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    width: 100%;

    &--banner-content {
      height: 100%;
      width: 100%;
    }
  }

  &__content {
    background-color: $f-girot-neutral-neutral-20;
    padding: $f-girot-space-xl 4.5rem;
    width: 100%;
    height: 100%;
  }

  &__row {
    &--h1-progress {
      @include flexbox;
      justify-content: space-between;
      margin-top: $f-girot-space-s;
      margin-bottom: $f-girot-space-l;

      &__def-button {
        path {
          stroke: $f-girot-green-green-600;
        }
      }
    }

    &--tabs {
      margin: $f-girot-space-xl 0;

      &__uploaded-files {
        @include flexbox;
      }
    }

    &--next-button {
      @include flexbox;
      justify-content: flex-end;
    }
  }

  &__error-modal {
    &__icon {
      path {
        stroke: $white;
      }
    }
  }

  &__progress {
    &__info {
      @include flexbox;
    }

    &__help-icon {
      margin-left: 8px;
      path {
        stroke: $f-girot-neutral-neutral-100;
      }
    }
  }
}

.container {
  &--report {
    padding: 0;
  }
}
