@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin supplier-info-table-row {
}

.supplier-info-table-row {
  &__left {
    @include flexbox;
    margin-left: 8px;
  }
  &__pill {
    &--margin {
      margin-left: 4px;
    }
  }

  &__info {
    width: 100%;
    @include flexbox;
    justify-content: space-between;
    align-items: center;

    &__chevron {
      path {
        stroke: $f-girot-neutral-neutral-60 !important;
      }
    }
  }
}
