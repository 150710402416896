@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

section.wage-type-change-info {
  display: flex;
  flex-direction: column;
  gap: $f-girot-space-s;

  .wage-type-change-info__period-header {
    margin-bottom: $f-girot-space-xs;
    display: flex;
    align-items: center;
    gap: $f-girot-space-xs;
  }

  & .inline-edit__children {
    & span {
      margin-left: auto;
    }
  }
}
