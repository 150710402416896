@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.pay-type-mapping-tab {
  margin-top: $f-girot-space-m;
  display: flex;
  flex-direction: column;
  gap: $f-girot-space-m;

  &__text {
    padding: 0.25rem;
  }

  &__title {
    display: flex;
    flex-direction: column;
    margin-bottom: $f-girot-space-xxs;
  }

  &__wage-type-kub-input-label {
    & .checkbox__label {
      font-family: OpenSansBold;
    }
  }
}
