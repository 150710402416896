@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.accounting-component-type {
  &__accordion {
    &__header {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      & div {
        margin-right: 40px;
      }
    }

    &__list {
      &__item {
        margin-top: 10px;
        display: flex;
        flex-direction: column;

        &__inner-list {
          margin-left: 20px;

          &__add-container {
            display: flex;
            gap: 20px;
            flex-direction: column;
            .button {
              max-width: 200px;
            }
            &--buttons {
              display: flex;
              gap: 10px;
              flex-direction: row;
            }

            &--input {
              max-width: 200px;
            }
          }
        }
      }
    }
  }
}
