@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.scroll-base {
  &__container {
    &--overflow {
      position: relative;
      overflow-y: scroll;
    }
  }

  &--overflow {
    margin-right: 8px;
  }
}
