@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.parse-error-modal {
  &__icon {
    path {
      stroke: $f-girot-orange-orange-500;
    }
  }

  &--margin {
    margin-top: 8px;
  }
}
