@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.agreement-settings-tab {
  margin-top: $f-girot-space-s;
  display: flex;
  flex-direction: column;
  gap: $f-girot-space-s;

  &__tgl-label {
    display: flex;
    flex-direction: row;
    gap: $f-girot-space-s;
  }

  &__tgl {
    display: flex;
    flex-direction: row;
    gap: $f-girot-space-s;
  }

  &__button {
    flex: 1;
  }

  &__buttons {
    align-items: center;
    margin-top: $f-girot-space-s;
    justify-content: center;
    display: flex;
    gap: $f-girot-space-s;
  }
}
