@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin posting-rules-tab {
}

.posting-rules-tab {
  margin-top: $f-girot-space-m;

  &__search-input {
    margin-bottom: $f-girot-space-s;
  }

  &__filter-button {
    display: flex;
    justify-content: right;
    position: fixed;
    bottom: $f-girot-space-xl;
    right: $f-girot-space-xl;
  }
}
