@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.reporting-settings-tab {
  margin-top: $f-girot-space-l;
  display: flex;
  gap: $f-girot-space-l;

  &__module-card {
    max-width: 500px;
  }

  &__module-card-content {
    display: flex;
    width: 500px;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
  }
}
