@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.accounting-items-filters-modal {
  &__filters {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $f-girot-space-s;
  }

  &__icon {
    margin-right: $f-girot-space-xs;

    path {
      stroke: $f-girot-green-green-400;
    }
  }

  &__select {
    display: flex;
    flex-direction: column;
    gap: $f-girot-space-xxs;

    & button {
      min-width: 100%;
    }
  }

  &__date-section {
    grid-column: 1;
  }
}

.date-filter {
  display: flex;
  flex-direction: row;
  gap: $f-girot-space-l;

  &--left {
    justify-content: flex-start;
  }

  &--right {
    justify-content: flex-end;
  }

  &__period {
    display: flex;
    flex-direction: column;
    &__selects {
      display: flex;
      flex-direction: row;
    }
  }

  &__label {
    color: $f-girot-neutral-neutral-500;
  }
}