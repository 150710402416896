@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin filter-component {
}

.filter-component {
  @include flexbox;
  align-items: center;
  margin: $f-girot-space-s 0;
  gap: $f-girot-space-s;

  &__search {
    margin: 0;
    width: auto !important;
    flex-grow: 2;
  }

  &__dropdowns {
    @include flexbox;
  }
}
