@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.insurance-event-about-tab {
  margin-top: $f-girot-space-s;

  & .list-group--inline-edit {
    margin-bottom: $f-girot-space-s;
  }

  &__values {
    display: flex;
    gap: $f-girot-space-xxs;
  }
}
