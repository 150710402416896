@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.pgl-wage-type-details-drawer {
  display: flex;
  flex-direction: column;
  gap: $f-girot-space-s;

  &__heading {
    padding-bottom: $f-girot-space-xs;
  }

  & .inline-edit__children {
    text-align: right;
  }
}
