@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.posting-rule-details {
  margin-top: $f-girot-space-xs;

  &__container {
    margin: $f-girot-space-xs;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__property {
    flex-basis: calc(33% - 10px);
  }

  &__edit-buttons {
    margin-top: $f-girot-space-xs;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: auto;
  }
}

.posting-rule {
  display: flex;
  flex-direction: column;
  gap: $f-girot-space-m;

  & * {
    box-sizing: border-box;
  }

  &__posting-rule-component-accordion {
    margin: $f-girot-space-xs;
    display: flex;
    flex-direction: column;
    &--header {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: $f-girot-space-s;
      flex-direction: row;
      &--share-error {
        display: flex;
        gap: $f-girot-space-xs;
        align-items: center;

        &--text {
          color: #ae2336;
        }
        &--icon path {
          stroke: #ae2336;
        }
      }
    }
    &--text {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding-right: 15px;
    }
    &__body {
      background-color: $f-girot-neutral-neutral-20;
      border-radius: $f-girot-border-radius-s;
      padding: $f-girot-space-xs;
      display: flex;
      flex-direction: row;
      &--component {
        width: 100%;
      }
    }
  }
}

.posting-rule-components {
  &__container {
    margin-top: $f-girot-space-m;
    padding: $f-girot-space-m;
    background: $f-girot-neutral-neutral-30;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    &--share-banner {
      margin: $f-girot-space-m 0;
    }
    &__edit {
      display: flex;
      margin-top: $f-girot-space-m;
      margin-bottom: $f-girot-space-xs;
      &--switch {
        display: flex;
        flex: 1;
        flex-direction: column;
      }
      &--buttons {
        display: flex;
        flex-direction: row;
        gap: $f-girot-space-s;
      }
    }
  }
  &__posting-rule-component-share-input {
    width: 25%;
  }
}
