@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.accounting-settings-tab {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: $f-girot-space-m;
  margin-top: $f-girot-space-m;
  @media (max-width: 1900px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__cards {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: $f-girot-space-m;
  }

  &__card-content {
    min-width: 45rem;
    background-color: $f-girot-neutral-neutral-0;
    border-radius: $f-girot-border-radius-l;
    padding: $f-girot-space-s;
    display: flex;
    flex-direction: column;
    gap: $f-girot-space-m;
  }

  &__card-content-row {
    align-items: center;
    min-height: 40px;
    display: flex;
    gap: $f-girot-space-xs;
    &__year-end-closing-month {
      margin-right: $f-girot-space-m;
    }
  }

  &__card-content-label {
    margin-right: $f-girot-space-m;
  }
  &__card-content-title {
    min-width: 100%;
  }

  &__delete-button {
    width: 2.5rem;
    height: 2.5rem;
  }
  &__text-input {
    max-width: 200px;
  }
}
