@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.wage-details {
  display: flex;
  flex-direction: column;
  gap: $f-girot-space-s;

  &__label {
    display: flex;
    align-items: baseline;
    gap: $f-girot-space-xxs;

    &--text {
      color: $f-girot-neutral-neutral-600;
    }
  }

  &--disabled-in-draft {
    &:hover {
      & svg {
        & path {
          stroke: $f-girot-neutral-neutral-60;
        }
      }
      cursor: not-allowed;
    }
  }
}
