@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin examination {
}

.examination {
  &__banner-container {
    margin-top: 32px;
  }

  &__banner {
    margin-top: 16px;
  }

  &__tabs {
    margin-top: 40px;
  }
}
