@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.payment-due-date-tab {
  margin-top: $f-girot-space-s;
  display: flex;
  flex-direction: column;
  gap: $f-girot-space-s;

  &__drawer-navigation-label {
    display: flex;
    align-items: center;
    gap: $f-girot-space-xs;
  }
}
