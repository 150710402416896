@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.certify-table {
  &__row {
    &__alert {
      path {
        stroke: $f-girot-red-red-600;
      }
    }
  }
}
