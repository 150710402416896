@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.update-wages-payed-amount {
  display: flex;
  flex-direction: column;

  & .inline-edit__label {
    max-width: 50%;
  }

  &__pill {
    margin-bottom: $f-girot-space-s;
  }

  &__period-section {
    display: flex;
    flex-direction: column;
    gap: $f-girot-space-xs;
    margin-top: $f-girot-space-s;
    margin-bottom: $f-girot-space-s;
  }

  &__label {
    display: flex;
    align-items: center;
    gap: $f-girot-space-xs;
    flex-wrap: wrap;

    & .body2 {
      color: $f-girot-neutral-neutral-600;
    }
  }

  &__button {
    margin-top: $f-girot-space-s;
  }

  &__period-header {
    display: flex;
    align-items: center;
    gap: $f-girot-space-xs;
  }
}
