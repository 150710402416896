@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.reporting-employment-details {
  &__employment-details {
    display: flex;
    background-color: $f-girot-neutral-neutral-30;
    padding: $f-girot-space-xxs;
    border-radius: $f-girot-border-radius-l;
  }

  &__pills {
    display: flex;
    align-items: center;
    gap: $f-girot-space-xs;
  }
}
