@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.absence-wage-types-overview-drawer {
  @include flexbox;
  flex-direction: column;
  gap: $f-girot-space-s;
  margin-top: $f-girot-space-s;
}
