@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin insurance-events {
}

.reporting-events {
  &__header {
    display: grid;
    grid-template-columns: 1fr auto auto;
    gap: $f-girot-space-s;
    margin-bottom: $f-girot-space-s;
  }
}
