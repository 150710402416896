@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.employee-section-label {
  display: flex;
  align-items: center;
  gap: $f-girot-space-xs;

  & .tooltip__wrapper {
    z-index: $z-index-10;
  }

  &__draft-changed {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    width: 20px;
    height: 20px;
  }
}
