@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.users-drawer-about-tab {
  margin-top: $f-girot-space-m;
  &__locked-pill {
    margin-bottom: $f-girot-space-s;
  }
  &__lock-button-wrapper {
    margin-top: $f-girot-space-s;
    width: 100%;
  }
  &__lock-button {
    width: 100%;
  }
}
