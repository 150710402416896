@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin reporting {
}

.reporting-overview-header {
  width: 100%;
  @include flexbox;
  flex-direction: column;
  gap: $f-girot-space-s;
  align-items: flex-end;

  &__banner {
    width: 100%;
  }
}

.reporting-overview {
  max-width: 1850px;
  margin-top: $f-girot-space-xl;

  &__year-overview {
    width: 100%;
    @include flexbox;
    flex-direction: row;
    flex-wrap: wrap;
    gap: $f-girot-space-m;
    margin-bottom: $f-girot-space-xl;
  }
}
