@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin mandatory-data-settings-tab {
}

.mandatory-data-settings-tab {
  &__table {
    &__head {
      justify-content: center;
    }
  }
}

.drawer-sub-tab {
  &__table {
    margin-top: $f-girot-space-m;
  }
}


