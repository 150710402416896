@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.absence-sum-row {
  border-top: 1px solid $f-girot-neutral-neutral-30;

  &__text,
  &__sum {
    color: $f-girot-neutral-neutral-500;
  }

  &__sum {
    width: 100%;
    display: block;
    text-align: right;
  }
}
