@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.update-reemployment {
  display: flex;
  flex-direction: column;
  gap: $f-girot-space-xs;

  &__save-button {
    margin-top: $f-girot-space-xs;
    width: 100%;
  }

  &__label {
    color: $f-girot-neutral-neutral-600;
  }

}
