@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin policy-version-header {
}

.policy-version-header {
  height: auto;
  display: flex;
  margin-top: $f-girot-space-xs;
  margin-bottom: 32px;
  &__version-info {
    width: 100%;
    display: flex;
    &--pill {
      margin: 0px 4px;
    }
    &--icon {
      margin: 0px 4px;
    }
  }

  &__actions {
    display: flex;
    float: right;
    height: 36px;
    &--button {
      white-space: nowrap;
      margin: 0px 4px;
      padding: 8px 16px;
    }
  }
  &__banner {
    width: 100%;
    display: flex;
  }
}
