@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.wage-type-table {
  margin-top: $f-girot-space-l;

  &__kub-column {
    display: flex;
    justify-content: center;
  }

  &__kub-icon {
    & path {
      stroke: $f-girot-green-green-500;
    }
  }
}
