@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.agreement-registry-overview {
  margin-top: $f-girot-space-l;
  padding-bottom: $f-girot-space-l;

  &__list {
    display: flex;
    flex-direction: column;
  }
}
