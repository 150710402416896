@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.individual-choices-section {
  &__pills {
    display: flex;
    align-items: center;
    gap: $f-girot-space-xs;
  }
}
