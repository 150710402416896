@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.reporting-events-overview {
  display: flex;
  flex-direction: column;
  gap: $f-girot-space-s;

  &__table-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: $f-girot-space-s;

    &__info {
      display: flex;
      flex-direction: column;
      gap: $f-girot-space-xs;
    }

    &__buttons {
      display: flex;
      gap: $f-girot-space-xs;
    }
  }
}
