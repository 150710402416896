@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin update-end-date {
}

.update-end-date {
  height: auto;
}
