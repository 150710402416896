@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.period-select {
  display: flex;
  gap: $f-girot-space-xs;
  align-items: center;

  & .select__button--label {
    font-size: 14px;
    line-height: 1;
  }
}
