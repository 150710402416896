@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin add-accounting-row-modal {
}

.add-accounting-row-modal {
  display: flex;
  flex-direction: column;
  gap: $f-girot-space-s;
}

.add-accounting-row-modal__dropdown {
  & button {
    font-size: $f-girot-font-size-0 + px;
  }
}
