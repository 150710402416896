@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

div.pay-type-mapping-drawer {
  & div.drawer-navigation__label {
    width: 100%;
  }

  &__navigation-label {
    display: flex;
    align-items: center;
    gap: $f-girot-space-xs;
    justify-content: space-between;
    width: 100%;
  }

  &__title {
    display: flex;
    flex-direction: column;
    margin-bottom: $f-girot-space-s;
  }
}
