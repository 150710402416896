@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.policy-comment-entry {
  @include flexbox;
  margin-top: 20px;

  &__avatar {
    flex-direction: column;
    margin-top: auto;
  }
  &__content {
    flex-direction: column;
    width: 100%;
    padding-left: 20px;

    & &__meta {
      @include flexbox;
      margin-top: 4px;

      &--name {
        color: $f-girot-neutral-neutral-300;
      }

      &--date {
        color: $f-girot-neutral-neutral-300;
        margin-left: auto;
      }
    }
  }
}
