@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin employer-settings {
}

.employer-settings {
  @include flexbox;
  height: auto;
  margin-top: 24px;

  button,
  button:hover {
    background: $f-girot-neutral-neutral-0;
    color: $f-girot-neutral-neutral-800;
    font-size: 1rem;
  }

  &__content {
    @include flexbox;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: $f-girot-neutral-neutral-0;
    border-radius: 8px;
    padding: 10px;
    margin: 10px;
    width: 33%;
    height: 200px;
  }

  &__icon {
    @include flexbox;
    justify-content: center;
    align-items: center;
    background-color: $f-girot-green-green-100;
    border-radius: 40px;
    width: 40px;
    height: 40px;
  }
  &--settings-container {
    margin-top: $f-girot-space-xxl;

    &__typography {
      margin-bottom: $f-girot-space-m;
    }

    &--card-container {
      @include flexbox;
      flex-direction: row;
      flex-wrap: wrap;
      gap: $f-girot-space-l;
    }
  }
}
