@import 'src/styles/variables';
@import 'src/styles/global-alias';
@import 'src/styles/mixins';

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $z-index-10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: $f-girot-space-s;

  & ~ * {
    opacity: 0.4;
    filter: grayscale(1);
  }
}
