@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.period-input {
  display: flex;
  flex-direction: row;
  gap: $f-girot-space-l;

  &--left {
    justify-content: flex-start;
  }

  &--right {
    justify-content: flex-end;
  }

  &__period {
    display: flex;
    flex-direction: column;
    &__selects {
      display: flex;
      flex-direction: row;
    }
  }

  &__label {
    color: $f-girot-neutral-neutral-500;
  }
}
