@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.accounting-adjustments-tab {
  &__add-rule-modal {
    &--select-section {
      display: flex;
      flex-direction: column;
      gap: $f-girot-space-s;
      margin-top: $f-girot-space-s;
    }

    &--value-selection {
      display: flex;
      flex-direction: row;
      gap: 5px;
    }

    &--action-select-section {
      display: flex;
      flex-direction: column;
    }
  }
}