@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin create-contract-modal {
}

.create-contract-modal {
  display: flex;
  flex-direction: column;
  gap: $f-girot-space-s;
}

.create-contract-modal__dropdown {
  & button {
    font-size: $f-girot-font-size-0 + px;
  }
}
