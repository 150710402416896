@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin reporting-status-files-header {
  @include flexbox;
  align-items: center;
  margin-bottom: $f-girot-space-xs;
}

.reporting-status {
  min-width: 27.5rem;
  &__drawer {
    box-sizing: border-box;
    padding-bottom: 0;
    padding-right: 8px;
  }
  &__header {
    @include flexbox;
    flex-direction: column;
    align-items: flex-start;
    margin: $f-girot-space-m 0 $f-girot-space-xl 0;

    &__title {
      margin: 4px 0;
    }

    &__caption {
      color: $f-girot-neutral-neutral-300;
    }
  }
  &__search {
    margin-bottom: $f-girot-space-m;
    margin-right: 8px;
  }
  &__error {
    max-width: 8rem;
  }
  &__tabs {
    padding: $f-girot-space-xs 0;
    margin-right: 8px;
    &__tab {
      padding: 4px 24px;
    }
  }

  &__files {
    &__header {
      @include reporting-status-files-header();

      &--errors {
        @include reporting-status-files-header();
        margin-bottom: 0;
        margin-top: $f-girot-space-m;
      }

      &__icon {
        margin-left: 8px;
        height: 16px;
        width: 16px;
        path {
          stroke: $f-girot-green-green-400;
        }
      }
    }
  }
  &__employer-list {
    &__company-card {
      &--margin {
        margin-top: 8px;
      }
    }
  }
}
