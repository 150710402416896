@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.update-individual-choice-itp1-from-date {
  &__inputs {
    display: flex;
    gap: $f-girot-space-s;
    flex-direction: column;
  }
  &__date-header {
    display: flex;
    align-items: center;
    gap: $f-girot-space-xs;
  }
}
