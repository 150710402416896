@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.unpaid-invoices-list-item {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__date {
    width: fit-content;
    cursor: default;
  }

  &__header_text {
    flex: 2;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: $f-girot-space-xs;
  }

  &__titles {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: $f-girot-space-s;
    padding: 0 $f-girot-space-xs $f-girot-space-xs $f-girot-space-xs;

    & span {
      color: $f-girot-neutral-neutral-700;
    }
  }

  &__row {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: $f-girot-space-s;
    padding: $f-girot-space-xxs $f-girot-space-xs;
    align-items: center;
  }

  &__button {
    width: 100%;
    padding: 0;
    text-align: left;
    border: none;
    background: transparent;
    cursor: default;
    background-color: $f-girot-neutral-neutral-20;
    border-radius: $f-girot-border-radius-l;
  }
}
